<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()"
          v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      id="print"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه مراجعة اوامر التشغيل
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->
      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      />
      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true" />
      <Column
        field="id"
        header="الحاله"
        :sortable="true"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          {{ $getStatus(slotProps.data.status) }}
        </template>
      </Column>
      <Column field="createdAt" header="تاريخ الانشاء">
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.createdAt) }}
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data.contractsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>
            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="[slotProps.data]"
            v-if="slotProps.data.backDeliveryNote"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />

            <Column field="revisionDate" header="تاريخ الارسال للمراجعه" />
            <Column field="usersId.name" header="الموظف المسئول" />

            <Column field="revision" header="عداد مرات المراجعة">
              <template #body="slotProps2">
                {{
                  slotProps2.data.revision ? slotProps2.data.revision.length : 0
                }}
              </template>
            </Column>
            <Column field="revision" header="مراجعات سابقه">
              <template #body="slotProps2">
                <Button
                  type="button"
                  @click="openListShow(slotProps2.data.revision)"
                  icon="pi pi-bell"
                  class="p-button-warning p-ml-2  p-button-rounded"
                ></Button>
              </template>
            </Column>

            <Column field="revisionDate" header="تاريخ المراجعه">
              <template #body="slotProps2">
                {{ slotProps2.data.revisionDate }}
              </template>
            </Column>
            <!-- <Column
              field="id"
              header="ارجاع للتشغيل"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <p v-if="slotProps2.data.status != 'revision'">
                  تحت التشغيل
                </p>
                <Button
                  type="button"
                  v-if="slotProps2.data.status == 'revision'"
                  @click="toDelivery(slotProps2.data)"
                  icon="pi pi-reply"
                  class="p-button-info p-ml-2  p-button-rounded"
                ></Button>
              </template>
            </Column> -->
            <!-- <Column
              field="id"
              header="جاهز للتسليم"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <Button
                  type="button"
                  v-if="slotProps2.data.status == 'revision'"
                  @click="toRady(slotProps2.data)"
                  icon="pi pi-sign-out"
                  class="p-button-success p-ml-2  p-button-rounded"
                ></Button>
              </template>
            </Column> -->
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="التوزيع"
      :visible.sync="displayBasicToDelivery"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="revision" class="form-label">
              الملاحظات
            </label>
            <b-form-textarea
              id="textarea-small"
              name="revision"
              v-model="revision"
              placeholder=""
            ></b-form-textarea>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="حفظ"
          icon="pi pi-check"
          @click="toAddDelivery"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToDelivery = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
    <Dialog
      header="المراجعات"
      :visible.sync="listShow"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0 text-center">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item"
            v-for="item of this.listShowList"
            :key="item"
          >
            {{ item == '' ? '--' : item }}
          </li>
        </ul>
      </div>
      <template #footer>
        <Button
          label="اغلاق"
          icon="pi pi-times"
          @click="listShow = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,

      delivery: {
        revision: [],
        status: null,
      },
      revision: '',
      usersList: [],
      productsListId: null,
      installationsId: null,
      displayBasicToDelivery: false,
      listShow: false,
      listShowList: [],
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    openListShow(data) {
      this.listShowList = data;
      this.listShow = true;
    },
    toDelivery(data) {
      this.productsListId = data.id;

      this.displayBasicToDelivery = true;
      this.revision = '';
      this.delivery = {
        revision: data.revision ? data.revision : [],
        status: null,
      };
    },
    toAddDelivery() {
      this.displayBasicToDelivery = false;

      this.$http
        .put(`productsList/` + this.productsListId, {
          revision: [...this.delivery.revision, this.revision],
          status: null,
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    toRady(data) {
      this.$http
        .put(`productsList/` + data.id, {
          isReady: true,
          readyDate: new Date(),
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    getData() {
      this.$http.get(`installations/getAllOperations2`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
  },
};
</script>
